@import "./colors";
@import "./fonts";

* {
	font-family: "SF Pro Display", "Helvetica Neue", sans-serif;
	box-sizing: border-box;
}
body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
button {
	border: none;
	background: none;
	cursor: pointer;
	&:focus {
		outline: none;
	}
}
ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
